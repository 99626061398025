<template>
  <div class="libraryManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'libraryManagement'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="专业库管理" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="层次">
                <el-select
                  v-model="formInline.eduCategory"
                  placeholder="选择层次"
                  clearable
                >
                  <el-option v-for="(item, key, index) in $common.getEduCategory" :key="index" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="目录名称">
                <el-input
                  v-model="formInline.directoryName"
                  placeholder="目录名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="学科名称">
                <el-input
                  v-model="formInline.subjectName"
                  placeholder="学科名称"
                  clearable
                ></el-input>
              </el-form-item>
              <!-- <el-form-item>
                <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
                <el-button type="info" plain @click="resetEvent">重置</el-button>
              </el-form-item> -->
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain
                >搜索</el-button
              >
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            max-height="700"
            align="center"
          >
            <el-table-column
              prop="eduCategory"
              label="层次"
              align="center"
              width="100"
              :formatter="regEduCategory"
            >
            </el-table-column>
            <el-table-column prop="majorName" label="专业名称" align="center">
            </el-table-column>
            <el-table-column prop="majorCode" label="专业代码" align="center">
            </el-table-column>
            <el-table-column
              prop="subjectName"
              label="学科名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="subjectCode" label="学科代码" align="center">
            </el-table-column>
            <el-table-column
              prop="directoryName"
              label="目录名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="directoryCode"
              label="目录代码"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <!-- <el-table-column
              prop="createTime"
              label="操作时间"
              align="center"
              width="180">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime*1000, 'datetime')}}</span>
              </template>
            </el-table-column> -->
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import publicChildren from "../publicChildren";
import Pagination from "../Pagination";
import way from "../../api/encapsulation";
export default {
  name: "libraryManagement",
  components: {
    publicChildren,
    Pagination,
  },
  data() {
    return {
      activeName: "first",
      formInline: {
        eduCategory: "",
        directoryName: "",
        subjectName: "",
        page: 1,
        pageSize: 10,
        majorName: "",
      },
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if (newVal.name == "libraryManagement") {
          this.getlibData();
        }
      },
    },
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 获取专业库表格数据
    getlibData() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getlibData(obj, (res) => {
        this.loading = false;
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total,
          };
        }
      });
    },
    regEduCategory(row) {
      let edu = String(row.eduCategory);
      let val = this.$common.getEduCategory[edu];
      return val;
    },
    async regPayDate(row) {
      // console.log(row.payDate)
      let val = "";
      if (row.payDate) {
        console.log(666);
        val = await way.timestampReturnDate(row.payDate);
      }
      return val;
    },
    // 重置
    resetEvent() {
      this.formInline.eduCategory = "";
      this.formInline.directoryName = "";
      this.formInline.subjectName = "";
    },
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getlibData();
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getlibData();
    },
  },
};
</script>

<style lang="less">
.libraryManagement {
  height: 100%;
  .elScroll {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .search_sList {
    background-color: #fff;
    padding: 28px 40px;
    .top_opera {
      display: flex;
      .el-form {
        flex: 4;
      }
      .btn_area {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .table_sList {
    box-sizing: border-box;
    padding: 30px 40px;
    margin-top: 10px;
    background-color: #fff;
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
      border-right: none;
    }
    .el-pagination {
      text-align: right;
    }
  }
}
</style>